
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as index2gTLcqDdzWJXa3kSr3hCwj1XCa7d_45l20YYPROfQ53m8Meta } from "/codebuild/output/src1136870220/src/packages/nuxt/pages/index.vue?macro=true";
import { default as loginMB02kfRyRNyYkXAdjXsyP8F3bpxfoel2mW0zL84djlIMeta } from "/codebuild/output/src1136870220/src/packages/nuxt/pages/login.vue?macro=true";
import { default as verifyuz6VqpyrD2LpPP655UhwMLYqSE6R19IEG7lBcG3F6J4Meta } from "/codebuild/output/src1136870220/src/packages/nuxt/pages/verify.vue?macro=true";
import { default as sitemapvETg0jD2jYCBgBsESrpsza047dDP1jxK3Ns7inApjgMMeta } from "/codebuild/output/src1136870220/src/packages/nuxt/pages/sitemap.vue?macro=true";
import { default as about_45usGPeH2LW4YIFZZbcVzrqiQEZky0xajzv0wCRMwbkMY04Meta } from "/codebuild/output/src1136870220/src/packages/nuxt/pages/about-us.vue?macro=true";
import { default as registerH13hIFjRB9kiX_453rK7B_jcDqyZ6tybSU4xELaWxgEmgMeta } from "/codebuild/output/src1136870220/src/packages/nuxt/pages/register.vue?macro=true";
import { default as complaintsfcBSTLGca2lsHzvzexI6iQC_45LmveBeJm96l61OJYtw4Meta } from "/codebuild/output/src1136870220/src/packages/nuxt/pages/complaints.vue?macro=true";
import { default as contact_45us_45lzrpywNRQu0q7nTIyI9mcBZrbXDBbX7nvdDJdRPSWUMeta } from "/codebuild/output/src1136870220/src/packages/nuxt/pages/contact-us.vue?macro=true";
import { default as indexPFbkaJFBf1s9YMitQ0m2hLwWAl6u8uTY_45fl2zLvSTRAMeta } from "/codebuild/output/src1136870220/src/packages/nuxt/pages/media/index.vue?macro=true";
import { default as indexi5pn2va60jo99xOQdlp8Bj8qt3hqLme7gZWTFFW78oYMeta } from "/codebuild/output/src1136870220/src/packages/nuxt/pages/energy/index.vue?macro=true";
import { default as _91slug_93TonGonKdzp0c1R16thDgWoTekMp1ktEDu1dFmTAElJIMeta } from "/codebuild/output/src1136870220/src/packages/nuxt/pages/media/[slug].vue?macro=true";
import { default as partnershipskAReDZpgAQattvYRvubhF_iYDDepq9JFAwVdRPcWGs0Meta } from "/codebuild/output/src1136870220/src/packages/nuxt/pages/partnerships.vue?macro=true";
import { default as terms_45of_45useUmP_dnDmvLJHVrU9MJE_45vxARU7W_xashCInEyl4eugsMeta } from "/codebuild/output/src1136870220/src/packages/nuxt/pages/terms-of-use.vue?macro=true";
import { default as indexJYuxP9l8PiuxESJKUtsFI2J_45AePWU1otrSf04WuB6q4Meta } from "/codebuild/output/src1136870220/src/packages/nuxt/pages/careers/index.vue?macro=true";
import { default as privacy_45policyv7jdbfM_a3g43w86NWQrH7JtrFFNybPF7jDYVB2nca8Meta } from "/codebuild/output/src1136870220/src/packages/nuxt/pages/privacy-policy.vue?macro=true";
import { default as reset_45passwordKj1ao8Mq_9jSxwKk3g9XBjiZdEE6xRFtKIHz0eNTznQMeta } from "/codebuild/output/src1136870220/src/packages/nuxt/pages/reset-password.vue?macro=true";
import { default as indexcJPLj3rnbVgdXPLNbxgtzbLPPZvVsvFAXoy8JGrA7dMMeta } from "/codebuild/output/src1136870220/src/packages/nuxt/pages/car-loans/index.vue?macro=true";
import { default as indexm3ntxhK2BUza4OS7piv_45KeWOI_454sZLQKYnoe08sMB8QMeta } from "/codebuild/output/src1136870220/src/packages/nuxt/pages/dashboard/index.vue?macro=true";
import { default as forgot_45passwordlhZkt21sRKJup5er2_45IXjwJJ6QAcyUEZQHS8_HUaXo4Meta } from "/codebuild/output/src1136870220/src/packages/nuxt/pages/forgot-password.vue?macro=true";
import { default as errorDcWqZ0lMY_453FM0b_45vh1H9QgrMfq2URyuUGLnACZbArQMeta } from "/codebuild/output/src1136870220/src/packages/nuxt/pages/energy/cdr/error.vue?macro=true";
import { default as indexbLrl0XtPErVSdcR7gZKlsUJiiGwUC4IsmOKG9tTV8RAMeta } from "/codebuild/output/src1136870220/src/packages/nuxt/pages/home-loans/index.vue?macro=true";
import { default as _91slug_93Qsa0pA1Hpmp1d9poKN3ZFa89lDm1vGWmkc5zdsP_45tqkMeta } from "/codebuild/output/src1136870220/src/packages/nuxt/pages/[vertical]/[slug].vue?macro=true";
import { default as guides0a3_458VV3h_FpIEFH7Nz12DODP5dXzIxJl4uuha1IMWcMeta } from "/codebuild/output/src1136870220/src/packages/nuxt/pages/[vertical]/guides.vue?macro=true";
import { default as collection_45noticeO1dC_45NlJMKOWkGhh33f6_45O_Ow9AdxTD00NEyDIlBxaYMeta } from "/codebuild/output/src1136870220/src/packages/nuxt/pages/collection-notice.vue?macro=true";
import { default as indexztaaSmfrzKdOgB3OcvjxdkKgHzXfUgQgU8kO2wE5GfQMeta } from "/codebuild/output/src1136870220/src/packages/nuxt/pages/credit-cards/index.vue?macro=true";
import { default as set_45passwordWcGX9KcyhiMF1TZRjXdwFLILWwcKCPTFG4fdPd1zm5MMeta } from "/codebuild/output/src1136870220/src/packages/nuxt/pages/guest/set-password.vue?macro=true";
import { default as welcome_45backxvaDKbkVYUl5JKme1a4IxbD2plE5nYZSz3Usy6rDe_4Meta } from "/codebuild/output/src1136870220/src/packages/nuxt/pages/guest/welcome-back.vue?macro=true";
import { default as mergek18AKvNHXyKoo9bIanozI0jUkH1wGE1797wLyOTE_GoMeta } from "/codebuild/output/src1136870220/src/packages/nuxt/pages/authorization/merge.vue?macro=true";
import { default as indexK_45ZEsQ_5hwQeKw4BQwGwGOiA4xkokYzv_QjeMkle5FUMeta } from "/codebuild/output/src1136870220/src/packages/nuxt/pages/car-insurance/index.vue?macro=true";
import { default as _91slug_93Y_45aHAI9EbJP3i_AtxWFOFewbUdcjzGyy5fLghqBqhXoMeta } from "/codebuild/output/src1136870220/src/packages/nuxt/pages/credit-cards/[slug].vue?macro=true";
import { default as post_45sale_45thank_45youyMSdfbCgXB_9gAf9vOPW4_45IxUiiGUD3uT3ypxdA_MB8Meta } from "/codebuild/output/src1136870220/src/packages/nuxt/pages/post-sale-thank-you.vue?macro=true";
import { default as thank_45youSoc_lsZVqCmoLq61Hq8S9Dtq45Cz2VbiaWlcKkf4kvAMeta } from "/codebuild/output/src1136870220/src/packages/nuxt/pages/energy/cdr/thank-you.vue?macro=true";
import { default as indexAuVFg8q4uSSsoc5b99aZMWgkLQt8cykVWfpQcEkJ2fMMeta } from "/codebuild/output/src1136870220/src/packages/nuxt/pages/life-insurance/index.vue?macro=true";
import { default as indexOImbuT0xHwMzyQ5bJQ0yh9yBCVBk6dQWWlLcB4a53_458Meta } from "/codebuild/output/src1136870220/src/packages/nuxt/pages/personal-loans/index.vue?macro=true";
import { default as _91slug_93Dhnfe_45STXw8IbmhAR2bzqArT6G_45Lukhl_45l_ohATpRjUMeta } from "/codebuild/output/src1136870220/src/packages/nuxt/pages/preview/media/[slug].vue?macro=true";
import { default as index_45VJR_xKf91FE9FsHDz9HaAtmiqlgajtSRpCS5lHcQUYMeta } from "/codebuild/output/src1136870220/src/packages/nuxt/pages/dashboard/perks/index.vue?macro=true";
import { default as index6zwclI7dHTRAi5G9fViwWsLp9niTV1L2Y0tKEtXy8k4Meta } from "/codebuild/output/src1136870220/src/packages/nuxt/pages/expert-analysis/index.vue?macro=true";
import { default as _91author_93Oy4QtlI_4pHsIHCxdtcDW3uv6hYeYWBX4aQ2DsDkoCQMeta } from "/codebuild/output/src1136870220/src/packages/nuxt/pages/media/author/[author].vue?macro=true";
import { default as callbackAmdS41jY9WLMckAChQ8cr54m3v9GLjnjYBSQGBKfDCwMeta } from "/codebuild/output/src1136870220/src/packages/nuxt/pages/authorization/callback.vue?macro=true";
import { default as buyer_45protection_45claimvALQGpNai5ITu_ROFas_45bgyYboVnIikFbci16P5VP2cMeta } from "/codebuild/output/src1136870220/src/packages/nuxt/pages/buyer-protection-claim.vue?macro=true";
import { default as indexI77NBKnAUWAY6wRwLvo5jSCqJckIpCOC_45bgD2MWYUSQMeta } from "/codebuild/output/src1136870220/src/packages/nuxt/pages/dashboard/quotes/index.vue?macro=true";
import { default as thank_45youzhJmgzkcyu2MhK6eJtIlgkmbk_lekV_45jl2xs_T44FIEMeta } from "/codebuild/output/src1136870220/src/packages/nuxt/pages/energy/quote/thank-you.vue?macro=true";
import { default as errorPch_45ykb1o2Fxdl0vb3I_0d9jOe7H5YCjVzX1LZO2sd8Meta } from "/codebuild/output/src1136870220/src/packages/nuxt/pages/health-insurance/error.vue?macro=true";
import { default as indexS_O3R9pjcQj7YrZAWqU1O6GG0nZMG5_45CCGlh3oIjvsoMeta } from "/codebuild/output/src1136870220/src/packages/nuxt/pages/health-insurance/index.vue?macro=true";
import { default as index5vdReEWQFWRu9IazwkTMAr0Dxgh84Gfs9HhB_45FUDo70Meta } from "/codebuild/output/src1136870220/src/packages/nuxt/pages/dashboard/rewards/index.vue?macro=true";
import { default as errorMpYVJQ_cG4Wcj7dBIOCOaMNJB2H5Icm91v6XAR94_r8Meta } from "/codebuild/output/src1136870220/src/packages/nuxt/pages/energy/application/error.vue?macro=true";
import { default as _91step_93YU_45l0Jlrb6XDUDsyAnUjuytgrDf_45WYeb8xSgqvO7cqkMeta } from "/codebuild/output/src1136870220/src/packages/nuxt/pages/energy/quote/form/[step].vue?macro=true";
import { default as details4neXPA0Zf8HEcN4v1H8MkVTBpE3A9Lpv3Bay1eUiLukMeta } from "/codebuild/output/src1136870220/src/packages/nuxt/pages/energy/quote/form/details.vue?macro=true";
import { default as interest_45ratesTZCAlBNeSAY6oWzs8d_45vT5gLswK264SjkpkuQFB_JO8Meta } from "/codebuild/output/src1136870220/src/packages/nuxt/pages/home-loans/interest-rates.vue?macro=true";
import { default as _91slug_9371uE6qvVkNAVqYqstcdPpjw61u9Vg3tUJP50P2wg1GQMeta } from "/codebuild/output/src1136870220/src/packages/nuxt/pages/preview/[vertical]/[slug].vue?macro=true";
import { default as indexZ4ppwuqqzIyGLBdDy3pRh4SKqE5VYtJjZD7p6yQo6uQMeta } from "/codebuild/output/src1136870220/src/packages/nuxt/pages/[vertical]/companies/index.vue?macro=true";
import { default as account_45settingsvcSXIJ4GrMGCov4Siu0fiTv4t0HEVaZja1YzmNBpMDcMeta } from "/codebuild/output/src1136870220/src/packages/nuxt/pages/dashboard/account-settings.vue?macro=true";
import { default as indexBCKYSlc59mEYsnb4z5Ph2HUYiNXMZJDcaIHtOT6roykMeta } from "/codebuild/output/src1136870220/src/packages/nuxt/pages/dashboard/onboarding/index.vue?macro=true";
import { default as live_45chatwIpKP8_EbPc4vyvxNNemD17ks_45_454NhOeF3wRHLbVy90Meta } from "/codebuild/output/src1136870220/src/packages/nuxt/pages/health-insurance/live-chat.vue?macro=true";
import { default as post_45sale4QuYhQcZS5NSQixtTwj7wD5LezqD7gObTEQxGg92L3YMeta } from "/codebuild/output/src1136870220/src/packages/nuxt/pages/health-insurance/post-sale.vue?macro=true";
import { default as thank_45youcx8_eVtx9lPE_vn_45PzGjESzJvODEVoxLPxskyZ_OlR0Meta } from "/codebuild/output/src1136870220/src/packages/nuxt/pages/home-loans/quote/thank-you.vue?macro=true";
import { default as comprehensiveqw_45Ev6jm8fQwQBxoEgsLBs_aKHiVq6aHTdtnjs5T8s4Meta } from "/codebuild/output/src1136870220/src/packages/nuxt/pages/car-insurance/comprehensive.vue?macro=true";
import { default as _91slug_93Y0BWzff6uqLKa_45a1eZNZK7TCFv0l_45UFzZkrTEXaJn7AMeta } from "/codebuild/output/src1136870220/src/packages/nuxt/pages/credit-cards/preview/[slug].vue?macro=true";
import { default as addWSbsp7CURkESx1OYl_451SpWWjggnjkpjvXXcEWobkIXMMeta } from "/codebuild/output/src1136870220/src/packages/nuxt/pages/life-insurance/partners/add.vue?macro=true";
import { default as indexgZq5oWW_IdC1ob3g8SbdmdpaCN_45hVSmp_IX_jifXG38Meta } from "/codebuild/output/src1136870220/src/packages/nuxt/pages/[vertical]/schedule-callback/index.vue?macro=true";
import { default as _91response_93Incm5kIKGD6MU_PRqQgEV5sKoWwAXm8_452gPC7K_45BvIUMeta } from "/codebuild/output/src1136870220/src/packages/nuxt/pages/[vertical]/schedule-callback/[response].vue?macro=true";
import { default as schedule_45callbacks1qWFITEYvY9icBPioigqjT6stJm8O4hyBl17IXjoRcMeta } from "/codebuild/output/src1136870220/src/packages/nuxt/pages/[vertical]/schedule-callback.vue?macro=true";
import { default as _91slug_93GcCFbEw7VHyw8Drmepemef1_6r3ie5knCLbvz_pkkjQMeta } from "/codebuild/output/src1136870220/src/packages/nuxt/pages/credit-cards/redirect/[slug].vue?macro=true";
import { default as thank_45you3Alo3dqZrKGZEQ0wSNQV24VV31VB8yosGG_CmIG3OX0Meta } from "/codebuild/output/src1136870220/src/packages/nuxt/pages/energy/application/thank-you.vue?macro=true";
import { default as live_45chat_45bH29m7LxbBSzxt_45f3ZA6QYHDBFinQGiidhdYA8TnvAG8Meta } from "/codebuild/output/src1136870220/src/packages/nuxt/pages/health-insurance/live-chat-b.vue?macro=true";
import { default as index9d2QKzIE4FqtpnshD9_45NfeT_45WQAPKpYCMgPh7OoPb8cMeta } from "/codebuild/output/src1136870220/src/packages/nuxt/pages/home-loans/calculators/index.vue?macro=true";
import { default as _91step_93fr49JU8X7WyK7izIzl4MdmxHB7MaonPBWNyM_YL1pKAMeta } from "/codebuild/output/src1136870220/src/packages/nuxt/pages/home-loans/quote/form/[step].vue?macro=true";
import { default as _91slug_93J343TT_vb208Gslk5MwakX8hL_454_45IEfQTsqc3FJXxuYMeta } from "/codebuild/output/src1136870220/src/packages/nuxt/pages/car-insurance/redirect/[slug].vue?macro=true";
import { default as indexPFGSjqrURca27Hy_O7zrZfqF9UtuQxngiLcqk7W4izYMeta } from "/codebuild/output/src1136870220/src/packages/nuxt/pages/health-insurance/offers/index.vue?macro=true";
import { default as adds8rSkB8_u9D371vmQF8YRPj1hjqB0PhKwHs56IOgffMMeta } from "/codebuild/output/src1136870220/src/packages/nuxt/pages/health-insurance/partners/add.vue?macro=true";
import { default as _91type_930zWx8_fYSLTKZeHU_45FrMyoW9SwlD_456_1TjyoFHA1wNUMeta } from "/codebuild/output/src1136870220/src/packages/nuxt/pages/home-loans/calculators/[type].vue?macro=true";
import { default as details3Gonas6_pbpc2QMwX6wkOI89J2ZbPz9eCjZ6amJPj2YMeta } from "/codebuild/output/src1136870220/src/packages/nuxt/pages/home-loans/quote/form/details.vue?macro=true";
import { default as callbackniolSgJKmvfd8jrBzyy8fvpRxWjPMsgUDNl6w4ErxpoMeta } from "/codebuild/output/src1136870220/src/packages/nuxt/pages/life-insurance/quote/callback.vue?macro=true";
import { default as digital_45eic_X4_t1RkPMJRlKDEablSrwEZ26qt__45KmHJvVwtmpNRYMeta } from "/codebuild/output/src1136870220/src/packages/nuxt/pages/energy/application/digital-eic.vue?macro=true";
import { default as thank_45youv0N8lxFyUUvlIUVlWeXCnyhvGNDQhGHG_93waKoZF7MMeta } from "/codebuild/output/src1136870220/src/packages/nuxt/pages/personal-loans/quote/thank-you.vue?macro=true";
import { default as _91step_93dNXu8ru0mrAGVDKBeUmzJ8XEt49sexha0Qh3Wi5_45llsMeta } from "/codebuild/output/src1136870220/src/packages/nuxt/pages/car-insurance/quote/form/[step].vue?macro=true";
import { default as _91author_93k2lLROXGHg8Pfq7rMr3ExqbQXDpLusI0_7ymtMFHeMYMeta } from "/codebuild/output/src1136870220/src/packages/nuxt/pages/expert-analysis/author/[author].vue?macro=true";
import { default as comparisony_45Mlp1HAKtLHUQN0m3UdDG7AYiIikuTjFX3QW7rOzIYMeta } from "/codebuild/output/src1136870220/src/packages/nuxt/pages/life-insurance/quote/comparison.vue?macro=true";
import { default as step1zcNK_45WvMcaXZeO2waKWJeCKHPNeJmbvkYcNHYYiSzqcMeta } from "/codebuild/output/src1136870220/src/packages/nuxt/pages/life-insurance/quote/form/step1.vue?macro=true";
import { default as details6SfooSL1_45FPlsobcdl0URBKym1W4QnDhpNgEBeFMy8MMeta } from "/codebuild/output/src1136870220/src/packages/nuxt/pages/car-insurance/quote/form/details.vue?macro=true";
import { default as results_45dsZdyfBXd_9cAIvi9XEoCAueVzC_45jwcsMhsP1_45AFfsMeta } from "/codebuild/output/src1136870220/src/packages/nuxt/pages/car-insurance/quote/form/results.vue?macro=true";
import { default as indexXzEA8FWL0lBipdFaWGS34l5KWMw7Qlngg4ZHxxvvDjYMeta } from "/codebuild/output/src1136870220/src/packages/nuxt/pages/expert-analysis/[category]/index.vue?macro=true";
import { default as signupOW6m88v8lx8sxnoUUZ3TentxUZpsoLezk3dX719qtsQMeta } from "/codebuild/output/src1136870220/src/packages/nuxt/pages/health-insurance/partners/signup.vue?macro=true";
import { default as thank_45youFparW5SCdd6ecfaq_45aZCYfiVsKXYC3r1Au5HsyCRSUoMeta } from "/codebuild/output/src1136870220/src/packages/nuxt/pages/health-insurance/quote/thank-you.vue?macro=true";
import { default as _91step_93qq_5W3220eZ2EdYNjPFLDpHL2olKzkJxzGquxLF1SyQMeta } from "/codebuild/output/src1136870220/src/packages/nuxt/pages/personal-loans/quote/form/[step].vue?macro=true";
import { default as _91slug_93PdxXfMn2Z9HUjAjYAJPNMkBCn5besCw1fa23XNEKdmwMeta } from "/codebuild/output/src1136870220/src/packages/nuxt/pages/expert-analysis/[category]/[slug].vue?macro=true";
import { default as step0aj0QUHy_45Z3D8GasGlx_hEEsXA4xKx_45_4kSBSXrZ7oYsMeta } from "/codebuild/output/src1136870220/src/packages/nuxt/pages/health-insurance/quote/form/step0.vue?macro=true";
import { default as existing_45customersn1dkM8VH1qKB7z1g60v8JDg7IXmrjKo_hiEe7onJA5MMeta } from "/codebuild/output/src1136870220/src/packages/nuxt/pages/life-insurance/existing-customers.vue?macro=true";
import { default as thank_45youw9uZeMdK2V3uJvC2ToMpRWrN5HYtzFvym3Kngtd6m8EMeta } from "/codebuild/output/src1136870220/src/packages/nuxt/pages/life-insurance/partners/thank-you.vue?macro=true";
import { default as detailsLOJ39MpWZ0PkOk4lqVggTxSqSAdDlqswUPN8V2iG_l8Meta } from "/codebuild/output/src1136870220/src/packages/nuxt/pages/life-insurance/quote/form/details.vue?macro=true";
import { default as detailsVTKyM_45KZRgqNX2FheEFcECPcqrIG6U3n9WmBYI_pw0sMeta } from "/codebuild/output/src1136870220/src/packages/nuxt/pages/personal-loans/quote/form/details.vue?macro=true";
import { default as _91step_9391vh3E5uRrU0YLkqi4EMOEq51YIwRXMsY0v7GNIWl4cMeta } from "/codebuild/output/src1136870220/src/packages/nuxt/pages/health-insurance/quote/form/[step].vue?macro=true";
import { default as rebate06rbKs2MhNYasD_XcrVhtpVGNf3WVAVQKQqo_p_45ZnKAMeta } from "/codebuild/output/src1136870220/src/packages/nuxt/pages/dashboard/vault/[...product]/rebate.vue?macro=true";
import { default as _91step_93o_45nqhWFXCvBMeF2d7enKWGv5PPuV5rcUNMxh3RPEzt8Meta } from "/codebuild/output/src1136870220/src/packages/nuxt/pages/health-insurance/application/[step].vue?macro=true";
import { default as detailswnr4gFLO9Odum30TWo4WUA4k2XgedZwmpFr_MQ_45x5gsMeta } from "/codebuild/output/src1136870220/src/packages/nuxt/pages/health-insurance/quote/form/details.vue?macro=true";
import { default as step0XUjMXoxY_45yRm_4VqTTI_45ah7pTf9KeppqEm_4530VM6JMcMeta } from "/codebuild/output/src1136870220/src/packages/nuxt/pages/health-insurance/quote/policy/step0.vue?macro=true";
import { default as index6ahsF0PThBs4Cr_OFgrlfj59rgeWHpXXbEqFVsSquxgMeta } from "/codebuild/output/src1136870220/src/packages/nuxt/pages/health-insurance/quote/resume/index.vue?macro=true";
import { default as indexNWKevODtNatYjpqZPenfZ6f1obqSjMMXRj8sdhti5ngMeta } from "/codebuild/output/src1136870220/src/packages/nuxt/pages/life-insurance/quote/customer/index.vue?macro=true";
import { default as detailsEnvxFFxuVOvltIutlFzWcR_45zdFl0WwgL9RYuNdcextsMeta } from "/codebuild/output/src1136870220/src/packages/nuxt/pages/dashboard/vault/[...product]/details.vue?macro=true";
import { default as terms_45and_45conditionspARht99G0Q47nRonBgPMplcx2jkxADbH0p6gwdOvLlUMeta } from "/codebuild/output/src1136870220/src/packages/nuxt/pages/buyer-protection/terms-and-conditions.vue?macro=true";
import { default as _91step_93mRCsecFeixbFGFQDdufPpnw9lN4XHTEKLa_E6j6q8v8Meta } from "/codebuild/output/src1136870220/src/packages/nuxt/pages/health-insurance/applicationV2/[step].vue?macro=true";
import { default as index69dgQ6_ZDOC_45wptE9jNkls2GgAxgYUR6VNrKn2bst_454Meta } from "/codebuild/output/src1136870220/src/packages/nuxt/pages/health-insurance/quote/customer/index.vue?macro=true";
import { default as detailsmFcJewnu4AGZojx2WnSoqAd9jjj3UnyukQR7J06aJhEMeta } from "/codebuild/output/src1136870220/src/packages/nuxt/pages/health-insurance/quote/policy/details.vue?macro=true";
import { default as indexonFz9UiflCoIjajMN2y_5jzBWeTNWpE0YZDqvs0RcasMeta } from "/codebuild/output/src1136870220/src/packages/nuxt/pages/health-insurance/quotes-results/index.vue?macro=true";
import { default as successNMl1GmYjQX7HpUNjRBMfTUDvA_45RqIcSSgA0YeKaDnN8Meta } from "/codebuild/output/src1136870220/src/packages/nuxt/pages/life-insurance/quote/customer/success.vue?macro=true";
import { default as claim_45offerYZfT_45auZHxsz2lRUYoTyZ734J6JLEHwVsmP2xq9_Sp4Meta } from "/codebuild/output/src1136870220/src/packages/nuxt/pages/dashboard/offers/[vertical]/claim-offer.vue?macro=true";
import { default as lic_45detailseOAAIrPlGv4D_452PfPY7EbiAPO6bbVICFy8Ah3JMaSvAMeta } from "/codebuild/output/src1136870220/src/packages/nuxt/pages/dashboard/vault/[...product]/lic-details.vue?macro=true";
import { default as coffs_45harbourz3W1JEtoLL2e3FUJpe2gC4vbpK1H4dMWo6_HkZNdKPIMeta } from "/codebuild/output/src1136870220/src/packages/nuxt/pages/energy/compare-by-location/coffs-harbour.vue?macro=true";
import { default as _91step_930yDtm_45UYyFWFFtPMeTXL_45SEioKBC62zO94TmBjHhG_kMeta } from "/codebuild/output/src1136870220/src/packages/nuxt/pages/health-insurance/quote/policy/step/[step].vue?macro=true";
import { default as indexYVXC6d6zUQob64xws7_DnFalwE_EivuCPwxGLfspRBsMeta } from "/codebuild/output/src1136870220/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/index.vue?macro=true";
import { default as _91slug_93zkQuhDqIj6iTMrwj__j2WmXyQ2KmiExbZ_NePR200WwMeta } from "/codebuild/output/src1136870220/src/packages/nuxt/pages/preview/expert-analysis/[vertical]/[slug].vue?macro=true";
import { default as indexASpuWnltxiSHp14DFZJAOQQch0PMie4PkK5VAIgf5uUMeta } from "/codebuild/output/src1136870220/src/packages/nuxt/pages/health-insurance/application/payment/index.vue?macro=true";
import { default as _91step_93__Y_45I9WhPrHudftL9COJjJfVRDLxxRpOESmTl7P__s8Meta } from "/codebuild/output/src1136870220/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/[step].vue?macro=true";
import { default as thank_45you410ZrgLD_45RFChHjk_452_45zr8OuZ4WyfB11PXdfqsgkZswMeta } from "/codebuild/output/src1136870220/src/packages/nuxt/pages/life-insurance/quote/application/thank-you.vue?macro=true";
import { default as indexYYJoPFvxSizujw97SNWOXHDJv0BNZDn2mzGE7keW190Meta } from "/codebuild/output/src1136870220/src/packages/nuxt/pages/health-insurance/application/thankyou/index.vue?macro=true";
import { default as indexC5kBu2Az4FegSmHL8gd_Of5uGM7zTKAaUX98AS4eX6sMeta } from "/codebuild/output/src1136870220/src/packages/nuxt/pages/health-insurance/rate-rise-calculator/index.vue?macro=true";
import { default as detailsqN3Z_Fs6SZiioMZNFLU3WE_45vwsXLS3tot4IH8QBma5EMeta } from "/codebuild/output/src1136870220/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/details.vue?macro=true";
import { default as expiredlFkoWwUT_tkMuloELnFmOJLoibOu2ihmiDLIz6MaVZgMeta } from "/codebuild/output/src1136870220/src/packages/nuxt/pages/health-insurance/application/payment/expired.vue?macro=true";
import { default as indexV_45OOz67PpInKw0coMVxYzPD5RzqLjo2zwt7MGCKuGxAMeta } from "/codebuild/output/src1136870220/src/packages/nuxt/pages/life-insurance/income-protection/quote/index.vue?macro=true";
import { default as thank_45youvzSRwaHt0JM0INlc7eViMlTpWpEgpT4BXk2xGVzi440Meta } from "/codebuild/output/src1136870220/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/thank-you.vue?macro=true";
import { default as detailsY4LC9GjhEN_45KOZpQZTKIc_45Qt5xAeotntt0KOzgYDQXYMeta } from "/codebuild/output/src1136870220/src/packages/nuxt/pages/life-insurance/income-protection/quote/details.vue?macro=true";
import { default as pre_45assesmentoES6BjJuFN2O6ng8FRQd17VIe0u3dcInh01nUUJxJRAMeta } from "/codebuild/output/src1136870220/src/packages/nuxt/pages/life-insurance/quote/application/pre-assesment.vue?macro=true";
import { default as confirmationqNwFAcUCIC0u3piQWwLQWXt91jRZ4XasqpqQsYm4CfkMeta } from "/codebuild/output/src1136870220/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/confirmation.vue?macro=true";
import { default as completionuvWIHsMlYxp2yDeD5fjvFoiPBc0SeFciMB9XI94hfgkMeta } from "/codebuild/output/src1136870220/src/packages/nuxt/pages/dashboard/vault/[...product]/[vertical]/completion.vue?macro=true";
import { default as quotes_45resultsLwbGDSoiqyx5XzdFXWJuMy4_45BwZlCmo5QulIYPCejjcMeta } from "/codebuild/output/src1136870220/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/quotes-results.vue?macro=true";
import { default as confirmationXornAyx1d23BsD3YIQPnFB3a7c9GZWwducoeRi23xMwMeta } from "/codebuild/output/src1136870220/src/packages/nuxt/pages/life-insurance/income-protection/quote/confirmation.vue?macro=true";
import { default as _91step_93SCgdEtYogRvm6zd807p6N3Nb6WmGHHYSRBnbV6wP47cMeta } from "/codebuild/output/src1136870220/src/packages/nuxt/pages/dashboard/vault/[...product]/form/energy/step/[step].vue?macro=true";
import { default as _91step_93lkcE3Ue98rDBP68fW1vZ1BI1WR90vJmAdj0FLagOSk8Meta } from "/codebuild/output/src1136870220/src/packages/nuxt/pages/dashboard/vault/[...product]/form/generic/step/[step].vue?macro=true";
import { default as _91step_93gUTw46BQCWH0uJ2gcpS_45_tBVetMPb7RP685QPX_45VhVcMeta } from "/codebuild/output/src1136870220/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/application/[step].vue?macro=true";
import { default as _91step_93cI2ty5twKPRiWpq5_45ZJvfL9RUukibJJMTwTjv00XW_gMeta } from "/codebuild/output/src1136870220/src/packages/nuxt/pages/dashboard/vault/[...product]/form/home-loan/step/[step].vue?macro=true";
import { default as index_d5ejtn5KNUmlsTm836Da1eTDl_kW1TZWQPq4mWEsxoMeta } from "/codebuild/output/src1136870220/src/packages/nuxt/pages/health-insurance/students-offer/[university]/form/index.vue?macro=true";
import { default as additional_45detailsRZJpGyUkU587XQpo5Ydur2rNvVj671d3UOOzQ21j208Meta } from "/codebuild/output/src1136870220/src/packages/nuxt/pages/dashboard/vault/[...product]/[vertical]/additional-details.vue?macro=true";
import { default as _91step_93VNneN9i2sFDI0BFcQ7L3EHOH45O02VseAtq4ymAxb1EMeta } from "/codebuild/output/src1136870220/src/packages/nuxt/pages/dashboard/vault/[...product]/form/life-insurance/step/[step].vue?macro=true";
import { default as _91step_93_2W_45lwfXlWZ6FELa5EDbxEb2_KNA95akmerNYLGPoDgMeta } from "/codebuild/output/src1136870220/src/packages/nuxt/pages/dashboard/vault/[...product]/form/health-insurance/step/[step].vue?macro=true";
import { default as indexZvHqh3CefaO2_5pOqZ3lQa4Bs5ggnKGUOOtYJC3FwVwMeta } from "/codebuild/output/src1136870220/src/packages/nuxt/pages/health-insurance/students-offer/[university]/terms-conditions/index.vue?macro=true";
import { default as indexSVZR8P4qZwQjFKhuDirlMzKTMyqULYnmKjSGK_45OaKd8Meta } from "/codebuild/output/src1136870220/src/packages/nuxt/pages/health-insurance/budget-hub/health-insurance-average-cost-calculator/index.vue?macro=true";
export default [
  {
    name: "index",
    path: "/",
    component: () => import("/codebuild/output/src1136870220/src/packages/nuxt/pages/index.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: loginMB02kfRyRNyYkXAdjXsyP8F3bpxfoel2mW0zL84djlIMeta || {},
    component: () => import("/codebuild/output/src1136870220/src/packages/nuxt/pages/login.vue")
  },
  {
    name: "verify",
    path: "/verify",
    component: () => import("/codebuild/output/src1136870220/src/packages/nuxt/pages/verify.vue")
  },
  {
    name: "sitemap",
    path: "/sitemap",
    component: () => import("/codebuild/output/src1136870220/src/packages/nuxt/pages/sitemap.vue")
  },
  {
    name: "about-us",
    path: "/about-us",
    component: () => import("/codebuild/output/src1136870220/src/packages/nuxt/pages/about-us.vue")
  },
  {
    name: "register",
    path: "/register",
    meta: registerH13hIFjRB9kiX_453rK7B_jcDqyZ6tybSU4xELaWxgEmgMeta || {},
    component: () => import("/codebuild/output/src1136870220/src/packages/nuxt/pages/register.vue")
  },
  {
    name: "complaints",
    path: "/complaints",
    component: () => import("/codebuild/output/src1136870220/src/packages/nuxt/pages/complaints.vue")
  },
  {
    name: "contact-us",
    path: "/contact-us",
    component: () => import("/codebuild/output/src1136870220/src/packages/nuxt/pages/contact-us.vue")
  },
  {
    name: "media",
    path: "/media",
    component: () => import("/codebuild/output/src1136870220/src/packages/nuxt/pages/media/index.vue")
  },
  {
    name: "energy",
    path: "/energy",
    component: () => import("/codebuild/output/src1136870220/src/packages/nuxt/pages/energy/index.vue")
  },
  {
    name: "media-slug",
    path: "/media/:slug()",
    component: () => import("/codebuild/output/src1136870220/src/packages/nuxt/pages/media/[slug].vue")
  },
  {
    name: "partnerships",
    path: "/partnerships",
    component: () => import("/codebuild/output/src1136870220/src/packages/nuxt/pages/partnerships.vue")
  },
  {
    name: "terms-of-use",
    path: "/terms-of-use",
    component: () => import("/codebuild/output/src1136870220/src/packages/nuxt/pages/terms-of-use.vue")
  },
  {
    name: "careers",
    path: "/careers",
    component: () => import("/codebuild/output/src1136870220/src/packages/nuxt/pages/careers/index.vue")
  },
  {
    name: "privacy-policy",
    path: "/privacy-policy",
    component: () => import("/codebuild/output/src1136870220/src/packages/nuxt/pages/privacy-policy.vue")
  },
  {
    name: "reset-password",
    path: "/reset-password",
    meta: reset_45passwordKj1ao8Mq_9jSxwKk3g9XBjiZdEE6xRFtKIHz0eNTznQMeta || {},
    component: () => import("/codebuild/output/src1136870220/src/packages/nuxt/pages/reset-password.vue")
  },
  {
    name: "car-loans",
    path: "/car-loans",
    component: () => import("/codebuild/output/src1136870220/src/packages/nuxt/pages/car-loans/index.vue")
  },
  {
    name: "dashboard",
    path: "/dashboard",
    meta: indexm3ntxhK2BUza4OS7piv_45KeWOI_454sZLQKYnoe08sMB8QMeta || {},
    component: () => import("/codebuild/output/src1136870220/src/packages/nuxt/pages/dashboard/index.vue")
  },
  {
    name: "forgot-password",
    path: "/forgot-password",
    meta: forgot_45passwordlhZkt21sRKJup5er2_45IXjwJJ6QAcyUEZQHS8_HUaXo4Meta || {},
    component: () => import("/codebuild/output/src1136870220/src/packages/nuxt/pages/forgot-password.vue")
  },
  {
    name: "energy-cdr-error",
    path: "/energy/cdr/error",
    component: () => import("/codebuild/output/src1136870220/src/packages/nuxt/pages/energy/cdr/error.vue")
  },
  {
    name: "home-loans",
    path: "/home-loans",
    component: () => import("/codebuild/output/src1136870220/src/packages/nuxt/pages/home-loans/index.vue")
  },
  {
    name: "vertical-slug",
    path: "/:vertical()/:slug()",
    component: () => import("/codebuild/output/src1136870220/src/packages/nuxt/pages/[vertical]/[slug].vue")
  },
  {
    name: "vertical-guides",
    path: "/:vertical()/guides",
    component: () => import("/codebuild/output/src1136870220/src/packages/nuxt/pages/[vertical]/guides.vue")
  },
  {
    name: "collection-notice",
    path: "/collection-notice",
    component: () => import("/codebuild/output/src1136870220/src/packages/nuxt/pages/collection-notice.vue")
  },
  {
    name: "credit-cards",
    path: "/credit-cards",
    component: () => import("/codebuild/output/src1136870220/src/packages/nuxt/pages/credit-cards/index.vue")
  },
  {
    name: "guest-set-password",
    path: "/guest/set-password",
    meta: set_45passwordWcGX9KcyhiMF1TZRjXdwFLILWwcKCPTFG4fdPd1zm5MMeta || {},
    component: () => import("/codebuild/output/src1136870220/src/packages/nuxt/pages/guest/set-password.vue")
  },
  {
    name: "guest-welcome-back",
    path: "/guest/welcome-back",
    component: () => import("/codebuild/output/src1136870220/src/packages/nuxt/pages/guest/welcome-back.vue")
  },
  {
    name: "authorization-merge",
    path: "/authorization/merge",
    component: () => import("/codebuild/output/src1136870220/src/packages/nuxt/pages/authorization/merge.vue")
  },
  {
    name: "car-insurance",
    path: "/car-insurance",
    component: () => import("/codebuild/output/src1136870220/src/packages/nuxt/pages/car-insurance/index.vue")
  },
  {
    name: "credit-cards-slug",
    path: "/credit-cards/:slug()",
    meta: _91slug_93Y_45aHAI9EbJP3i_AtxWFOFewbUdcjzGyy5fLghqBqhXoMeta || {},
    component: () => import("/codebuild/output/src1136870220/src/packages/nuxt/pages/credit-cards/[slug].vue")
  },
  {
    name: "post-sale-thank-you",
    path: "/post-sale-thank-you",
    component: () => import("/codebuild/output/src1136870220/src/packages/nuxt/pages/post-sale-thank-you.vue")
  },
  {
    name: "energy-cdr-thank-you",
    path: "/energy/cdr/thank-you",
    component: () => import("/codebuild/output/src1136870220/src/packages/nuxt/pages/energy/cdr/thank-you.vue")
  },
  {
    name: "life-insurance",
    path: "/life-insurance",
    component: () => import("/codebuild/output/src1136870220/src/packages/nuxt/pages/life-insurance/index.vue")
  },
  {
    name: "personal-loans",
    path: "/personal-loans",
    component: () => import("/codebuild/output/src1136870220/src/packages/nuxt/pages/personal-loans/index.vue")
  },
  {
    name: "preview-media-slug",
    path: "/preview/media/:slug()",
    component: () => import("/codebuild/output/src1136870220/src/packages/nuxt/pages/preview/media/[slug].vue")
  },
  {
    name: "dashboard-perks",
    path: "/dashboard/perks",
    meta: index_45VJR_xKf91FE9FsHDz9HaAtmiqlgajtSRpCS5lHcQUYMeta || {},
    component: () => import("/codebuild/output/src1136870220/src/packages/nuxt/pages/dashboard/perks/index.vue")
  },
  {
    name: "expert-analysis",
    path: "/expert-analysis",
    component: () => import("/codebuild/output/src1136870220/src/packages/nuxt/pages/expert-analysis/index.vue")
  },
  {
    name: "media-author-author",
    path: "/media/author/:author()",
    component: () => import("/codebuild/output/src1136870220/src/packages/nuxt/pages/media/author/[author].vue")
  },
  {
    name: "authorization-callback",
    path: "/authorization/callback",
    component: () => import("/codebuild/output/src1136870220/src/packages/nuxt/pages/authorization/callback.vue")
  },
  {
    name: "buyer-protection-claim",
    path: "/buyer-protection-claim",
    component: () => import("/codebuild/output/src1136870220/src/packages/nuxt/pages/buyer-protection-claim.vue")
  },
  {
    name: "dashboard-quotes",
    path: "/dashboard/quotes",
    meta: indexI77NBKnAUWAY6wRwLvo5jSCqJckIpCOC_45bgD2MWYUSQMeta || {},
    component: () => import("/codebuild/output/src1136870220/src/packages/nuxt/pages/dashboard/quotes/index.vue")
  },
  {
    name: "energy-quote-thank-you",
    path: "/energy/quote/thank-you",
    component: () => import("/codebuild/output/src1136870220/src/packages/nuxt/pages/energy/quote/thank-you.vue")
  },
  {
    name: "health-insurance-error",
    path: "/health-insurance/error",
    component: () => import("/codebuild/output/src1136870220/src/packages/nuxt/pages/health-insurance/error.vue")
  },
  {
    name: "health-insurance",
    path: "/health-insurance",
    component: () => import("/codebuild/output/src1136870220/src/packages/nuxt/pages/health-insurance/index.vue")
  },
  {
    name: "dashboard-rewards",
    path: "/dashboard/rewards",
    props: index5vdReEWQFWRu9IazwkTMAr0Dxgh84Gfs9HhB_45FUDo70Meta?.props ?? {"verificationNeeded":true},
    meta: index5vdReEWQFWRu9IazwkTMAr0Dxgh84Gfs9HhB_45FUDo70Meta || {},
    component: () => import("/codebuild/output/src1136870220/src/packages/nuxt/pages/dashboard/rewards/index.vue")
  },
  {
    name: "energy-application-error",
    path: "/energy/application/error",
    component: () => import("/codebuild/output/src1136870220/src/packages/nuxt/pages/energy/application/error.vue")
  },
  {
    name: "energy-quote-form-step",
    path: "/energy/quote/form/:step()",
    component: () => import("/codebuild/output/src1136870220/src/packages/nuxt/pages/energy/quote/form/[step].vue")
  },
  {
    name: "energy-quote-form-details",
    path: "/energy/quote/form/details",
    component: () => import("/codebuild/output/src1136870220/src/packages/nuxt/pages/energy/quote/form/details.vue")
  },
  {
    name: "home-loans-interest-rates",
    path: "/home-loans/interest-rates",
    component: () => import("/codebuild/output/src1136870220/src/packages/nuxt/pages/home-loans/interest-rates.vue")
  },
  {
    name: "preview-vertical-slug",
    path: "/preview/:vertical()/:slug()",
    component: () => import("/codebuild/output/src1136870220/src/packages/nuxt/pages/preview/[vertical]/[slug].vue")
  },
  {
    name: "vertical-companies",
    path: "/:vertical()/companies",
    component: () => import("/codebuild/output/src1136870220/src/packages/nuxt/pages/[vertical]/companies/index.vue")
  },
  {
    name: "dashboard-account-settings",
    path: "/dashboard/account-settings",
    meta: account_45settingsvcSXIJ4GrMGCov4Siu0fiTv4t0HEVaZja1YzmNBpMDcMeta || {},
    component: () => import("/codebuild/output/src1136870220/src/packages/nuxt/pages/dashboard/account-settings.vue")
  },
  {
    name: "dashboard-onboarding",
    path: "/dashboard/onboarding",
    meta: indexBCKYSlc59mEYsnb4z5Ph2HUYiNXMZJDcaIHtOT6roykMeta || {},
    component: () => import("/codebuild/output/src1136870220/src/packages/nuxt/pages/dashboard/onboarding/index.vue")
  },
  {
    name: "health-insurance-live-chat",
    path: "/health-insurance/live-chat",
    component: () => import("/codebuild/output/src1136870220/src/packages/nuxt/pages/health-insurance/live-chat.vue")
  },
  {
    name: "health-insurance-post-sale",
    path: "/health-insurance/post-sale",
    component: () => import("/codebuild/output/src1136870220/src/packages/nuxt/pages/health-insurance/post-sale.vue")
  },
  {
    name: "home-loans-quote-thank-you",
    path: "/home-loans/quote/thank-you",
    component: () => import("/codebuild/output/src1136870220/src/packages/nuxt/pages/home-loans/quote/thank-you.vue")
  },
  {
    name: "car-insurance-comprehensive",
    path: "/car-insurance/comprehensive",
    component: () => import("/codebuild/output/src1136870220/src/packages/nuxt/pages/car-insurance/comprehensive.vue")
  },
  {
    name: "credit-cards-preview-slug",
    path: "/credit-cards/preview/:slug()",
    component: () => import("/codebuild/output/src1136870220/src/packages/nuxt/pages/credit-cards/preview/[slug].vue")
  },
  {
    name: "life-insurance-partners-add",
    path: "/life-insurance/partners/add",
    component: () => import("/codebuild/output/src1136870220/src/packages/nuxt/pages/life-insurance/partners/add.vue")
  },
  {
    name: schedule_45callbacks1qWFITEYvY9icBPioigqjT6stJm8O4hyBl17IXjoRcMeta?.name,
    path: "/:vertical()/schedule-callback",
    component: () => import("/codebuild/output/src1136870220/src/packages/nuxt/pages/[vertical]/schedule-callback.vue"),
    children: [
  {
    name: "vertical-schedule-callback",
    path: "",
    component: () => import("/codebuild/output/src1136870220/src/packages/nuxt/pages/[vertical]/schedule-callback/index.vue")
  },
  {
    name: "vertical-schedule-callback-response",
    path: ":response()",
    component: () => import("/codebuild/output/src1136870220/src/packages/nuxt/pages/[vertical]/schedule-callback/[response].vue")
  }
]
  },
  {
    name: "credit-cards-redirect-slug",
    path: "/credit-cards/redirect/:slug()",
    component: () => import("/codebuild/output/src1136870220/src/packages/nuxt/pages/credit-cards/redirect/[slug].vue")
  },
  {
    name: "energy-application-thank-you",
    path: "/energy/application/thank-you",
    component: () => import("/codebuild/output/src1136870220/src/packages/nuxt/pages/energy/application/thank-you.vue")
  },
  {
    name: "health-insurance-live-chat-b",
    path: "/health-insurance/live-chat-b",
    component: () => import("/codebuild/output/src1136870220/src/packages/nuxt/pages/health-insurance/live-chat-b.vue")
  },
  {
    name: "home-loans-calculators",
    path: "/home-loans/calculators",
    component: () => import("/codebuild/output/src1136870220/src/packages/nuxt/pages/home-loans/calculators/index.vue")
  },
  {
    name: "home-loans-quote-form-step",
    path: "/home-loans/quote/form/:step()",
    component: () => import("/codebuild/output/src1136870220/src/packages/nuxt/pages/home-loans/quote/form/[step].vue")
  },
  {
    name: "car-insurance-redirect-slug",
    path: "/car-insurance/redirect/:slug()",
    component: () => import("/codebuild/output/src1136870220/src/packages/nuxt/pages/car-insurance/redirect/[slug].vue")
  },
  {
    name: "health-insurance-offers",
    path: "/health-insurance/offers",
    component: () => import("/codebuild/output/src1136870220/src/packages/nuxt/pages/health-insurance/offers/index.vue")
  },
  {
    name: "health-insurance-partners-add",
    path: "/health-insurance/partners/add",
    component: () => import("/codebuild/output/src1136870220/src/packages/nuxt/pages/health-insurance/partners/add.vue")
  },
  {
    name: "home-loans-calculators-type",
    path: "/home-loans/calculators/:type()",
    component: () => import("/codebuild/output/src1136870220/src/packages/nuxt/pages/home-loans/calculators/[type].vue")
  },
  {
    name: "home-loans-quote-form-details",
    path: "/home-loans/quote/form/details",
    component: () => import("/codebuild/output/src1136870220/src/packages/nuxt/pages/home-loans/quote/form/details.vue")
  },
  {
    name: "life-insurance-quote-callback",
    path: "/life-insurance/quote/callback",
    component: () => import("/codebuild/output/src1136870220/src/packages/nuxt/pages/life-insurance/quote/callback.vue")
  },
  {
    name: "energy-application-digital-eic",
    path: "/energy/application/digital-eic",
    component: () => import("/codebuild/output/src1136870220/src/packages/nuxt/pages/energy/application/digital-eic.vue")
  },
  {
    name: "personal-loans-quote-thank-you",
    path: "/personal-loans/quote/thank-you",
    component: () => import("/codebuild/output/src1136870220/src/packages/nuxt/pages/personal-loans/quote/thank-you.vue")
  },
  {
    name: "car-insurance-quote-form-step",
    path: "/car-insurance/quote/form/:step()",
    component: () => import("/codebuild/output/src1136870220/src/packages/nuxt/pages/car-insurance/quote/form/[step].vue")
  },
  {
    name: "expert-analysis-author-author",
    path: "/expert-analysis/author/:author()",
    component: () => import("/codebuild/output/src1136870220/src/packages/nuxt/pages/expert-analysis/author/[author].vue")
  },
  {
    name: "life-insurance-quote-comparison",
    path: "/life-insurance/quote/comparison",
    component: () => import("/codebuild/output/src1136870220/src/packages/nuxt/pages/life-insurance/quote/comparison.vue")
  },
  {
    name: "life-insurance-quote-form-step1",
    path: "/life-insurance/quote/form/step1",
    component: () => import("/codebuild/output/src1136870220/src/packages/nuxt/pages/life-insurance/quote/form/step1.vue")
  },
  {
    name: "car-insurance-quote-form-details",
    path: "/car-insurance/quote/form/details",
    component: () => import("/codebuild/output/src1136870220/src/packages/nuxt/pages/car-insurance/quote/form/details.vue")
  },
  {
    name: "car-insurance-quote-form-results",
    path: "/car-insurance/quote/form/results",
    component: () => import("/codebuild/output/src1136870220/src/packages/nuxt/pages/car-insurance/quote/form/results.vue")
  },
  {
    name: "expert-analysis-category",
    path: "/expert-analysis/:category()",
    component: () => import("/codebuild/output/src1136870220/src/packages/nuxt/pages/expert-analysis/[category]/index.vue")
  },
  {
    name: "health-insurance-partners-signup",
    path: "/health-insurance/partners/signup",
    component: () => import("/codebuild/output/src1136870220/src/packages/nuxt/pages/health-insurance/partners/signup.vue")
  },
  {
    name: "health-insurance-quote-thank-you",
    path: "/health-insurance/quote/thank-you",
    component: () => import("/codebuild/output/src1136870220/src/packages/nuxt/pages/health-insurance/quote/thank-you.vue")
  },
  {
    name: "personal-loans-quote-form-step",
    path: "/personal-loans/quote/form/:step()",
    component: () => import("/codebuild/output/src1136870220/src/packages/nuxt/pages/personal-loans/quote/form/[step].vue")
  },
  {
    name: "expert-analysis-category-slug",
    path: "/expert-analysis/:category()/:slug()",
    component: () => import("/codebuild/output/src1136870220/src/packages/nuxt/pages/expert-analysis/[category]/[slug].vue")
  },
  {
    name: "health-insurance-quote-form-step0",
    path: "/health-insurance/quote/form/step0",
    component: () => import("/codebuild/output/src1136870220/src/packages/nuxt/pages/health-insurance/quote/form/step0.vue")
  },
  {
    name: "life-insurance-existing-customers",
    path: "/life-insurance/existing-customers",
    component: () => import("/codebuild/output/src1136870220/src/packages/nuxt/pages/life-insurance/existing-customers.vue")
  },
  {
    name: "life-insurance-partners-thank-you",
    path: "/life-insurance/partners/thank-you",
    component: () => import("/codebuild/output/src1136870220/src/packages/nuxt/pages/life-insurance/partners/thank-you.vue")
  },
  {
    name: "life-insurance-quote-form-details",
    path: "/life-insurance/quote/form/details",
    component: () => import("/codebuild/output/src1136870220/src/packages/nuxt/pages/life-insurance/quote/form/details.vue")
  },
  {
    name: "personal-loans-quote-form-details",
    path: "/personal-loans/quote/form/details",
    component: () => import("/codebuild/output/src1136870220/src/packages/nuxt/pages/personal-loans/quote/form/details.vue")
  },
  {
    name: "health-insurance-quote-form-step",
    path: "/health-insurance/quote/form/:step()",
    component: () => import("/codebuild/output/src1136870220/src/packages/nuxt/pages/health-insurance/quote/form/[step].vue")
  },
  {
    name: "dashboard-vault-product-rebate",
    path: "/dashboard/vault/:product([^/]*)*/rebate",
    meta: rebate06rbKs2MhNYasD_XcrVhtpVGNf3WVAVQKQqo_p_45ZnKAMeta || {},
    component: () => import("/codebuild/output/src1136870220/src/packages/nuxt/pages/dashboard/vault/[...product]/rebate.vue")
  },
  {
    name: "health-insurance-application-step",
    path: "/health-insurance/application/:step()",
    component: () => import("/codebuild/output/src1136870220/src/packages/nuxt/pages/health-insurance/application/[step].vue")
  },
  {
    name: "health-insurance-quote-form-details",
    path: "/health-insurance/quote/form/details",
    component: () => import("/codebuild/output/src1136870220/src/packages/nuxt/pages/health-insurance/quote/form/details.vue")
  },
  {
    name: "health-insurance-quote-policy-step0",
    path: "/health-insurance/quote/policy/step0",
    component: () => import("/codebuild/output/src1136870220/src/packages/nuxt/pages/health-insurance/quote/policy/step0.vue")
  },
  {
    name: "health-insurance-quote-resume",
    path: "/health-insurance/quote/resume",
    component: () => import("/codebuild/output/src1136870220/src/packages/nuxt/pages/health-insurance/quote/resume/index.vue")
  },
  {
    name: "life-insurance-quote-customer",
    path: "/life-insurance/quote/customer",
    component: () => import("/codebuild/output/src1136870220/src/packages/nuxt/pages/life-insurance/quote/customer/index.vue")
  },
  {
    name: "dashboard-vault-product-details",
    path: "/dashboard/vault/:product([^/]*)*/details",
    meta: detailsEnvxFFxuVOvltIutlFzWcR_45zdFl0WwgL9RYuNdcextsMeta || {},
    component: () => import("/codebuild/output/src1136870220/src/packages/nuxt/pages/dashboard/vault/[...product]/details.vue")
  },
  {
    name: "buyer-protection-terms-and-conditions",
    path: "/buyer-protection/terms-and-conditions",
    component: () => import("/codebuild/output/src1136870220/src/packages/nuxt/pages/buyer-protection/terms-and-conditions.vue")
  },
  {
    name: "health-insurance-applicationV2-step",
    path: "/health-insurance/applicationV2/:step()",
    component: () => import("/codebuild/output/src1136870220/src/packages/nuxt/pages/health-insurance/applicationV2/[step].vue")
  },
  {
    name: "health-insurance-quote-customer",
    path: "/health-insurance/quote/customer",
    component: () => import("/codebuild/output/src1136870220/src/packages/nuxt/pages/health-insurance/quote/customer/index.vue")
  },
  {
    name: "health-insurance-quote-policy-details",
    path: "/health-insurance/quote/policy/details",
    component: () => import("/codebuild/output/src1136870220/src/packages/nuxt/pages/health-insurance/quote/policy/details.vue")
  },
  {
    name: "health-insurance-quotes-results",
    path: "/health-insurance/quotes-results",
    component: () => import("/codebuild/output/src1136870220/src/packages/nuxt/pages/health-insurance/quotes-results/index.vue")
  },
  {
    name: "life-insurance-quote-customer-success",
    path: "/life-insurance/quote/customer/success",
    component: () => import("/codebuild/output/src1136870220/src/packages/nuxt/pages/life-insurance/quote/customer/success.vue")
  },
  {
    name: "dashboard-offers-vertical-claim-offer",
    path: "/dashboard/offers/:vertical()/claim-offer",
    meta: claim_45offerYZfT_45auZHxsz2lRUYoTyZ734J6JLEHwVsmP2xq9_Sp4Meta || {},
    component: () => import("/codebuild/output/src1136870220/src/packages/nuxt/pages/dashboard/offers/[vertical]/claim-offer.vue")
  },
  {
    name: "dashboard-vault-product-lic-details",
    path: "/dashboard/vault/:product([^/]*)*/lic-details",
    meta: lic_45detailseOAAIrPlGv4D_452PfPY7EbiAPO6bbVICFy8Ah3JMaSvAMeta || {},
    component: () => import("/codebuild/output/src1136870220/src/packages/nuxt/pages/dashboard/vault/[...product]/lic-details.vue")
  },
  {
    name: "energy-compare-by-location-coffs-harbour",
    path: "/energy/compare-by-location/coffs-harbour",
    component: () => import("/codebuild/output/src1136870220/src/packages/nuxt/pages/energy/compare-by-location/coffs-harbour.vue")
  },
  {
    name: "health-insurance-quote-policy-step-step",
    path: "/health-insurance/quote/policy/step/:step()",
    meta: _91step_930yDtm_45UYyFWFFtPMeTXL_45SEioKBC62zO94TmBjHhG_kMeta || {},
    component: () => import("/codebuild/output/src1136870220/src/packages/nuxt/pages/health-insurance/quote/policy/step/[step].vue")
  },
  {
    name: "health-insurance-visitors-visa-ovhc",
    path: "/health-insurance/visitors-visa-ovhc",
    component: () => import("/codebuild/output/src1136870220/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/index.vue")
  },
  {
    name: "preview-expert-analysis-vertical-slug",
    path: "/preview/expert-analysis/:vertical()/:slug()",
    component: () => import("/codebuild/output/src1136870220/src/packages/nuxt/pages/preview/expert-analysis/[vertical]/[slug].vue")
  },
  {
    name: "health-insurance-application-payment",
    path: "/health-insurance/application/payment",
    component: () => import("/codebuild/output/src1136870220/src/packages/nuxt/pages/health-insurance/application/payment/index.vue")
  },
  {
    name: "health-insurance-visitors-visa-ovhc-step",
    path: "/health-insurance/visitors-visa-ovhc/:step()",
    component: () => import("/codebuild/output/src1136870220/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/[step].vue")
  },
  {
    name: "life-insurance-quote-application-thank-you",
    path: "/life-insurance/quote/application/thank-you",
    component: () => import("/codebuild/output/src1136870220/src/packages/nuxt/pages/life-insurance/quote/application/thank-you.vue")
  },
  {
    name: "health-insurance-application-thankyou",
    path: "/health-insurance/application/thankyou",
    component: () => import("/codebuild/output/src1136870220/src/packages/nuxt/pages/health-insurance/application/thankyou/index.vue")
  },
  {
    name: "health-insurance-rate-rise-calculator",
    path: "/health-insurance/rate-rise-calculator",
    component: () => import("/codebuild/output/src1136870220/src/packages/nuxt/pages/health-insurance/rate-rise-calculator/index.vue")
  },
  {
    name: "health-insurance-visitors-visa-ovhc-details",
    path: "/health-insurance/visitors-visa-ovhc/details",
    component: () => import("/codebuild/output/src1136870220/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/details.vue")
  },
  {
    name: "health-insurance-application-payment-expired",
    path: "/health-insurance/application/payment/expired",
    component: () => import("/codebuild/output/src1136870220/src/packages/nuxt/pages/health-insurance/application/payment/expired.vue")
  },
  {
    name: "life-insurance-income-protection-quote",
    path: "/life-insurance/income-protection/quote",
    component: () => import("/codebuild/output/src1136870220/src/packages/nuxt/pages/life-insurance/income-protection/quote/index.vue")
  },
  {
    name: "health-insurance-visitors-visa-ovhc-thank-you",
    path: "/health-insurance/visitors-visa-ovhc/thank-you",
    component: () => import("/codebuild/output/src1136870220/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/thank-you.vue")
  },
  {
    name: "life-insurance-income-protection-quote-details",
    path: "/life-insurance/income-protection/quote/details",
    component: () => import("/codebuild/output/src1136870220/src/packages/nuxt/pages/life-insurance/income-protection/quote/details.vue")
  },
  {
    name: "life-insurance-quote-application-pre-assesment",
    path: "/life-insurance/quote/application/pre-assesment",
    component: () => import("/codebuild/output/src1136870220/src/packages/nuxt/pages/life-insurance/quote/application/pre-assesment.vue")
  },
  {
    name: "health-insurance-visitors-visa-ovhc-confirmation",
    path: "/health-insurance/visitors-visa-ovhc/confirmation",
    component: () => import("/codebuild/output/src1136870220/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/confirmation.vue")
  },
  {
    name: "dashboard-vault-product-vertical-completion",
    path: "/dashboard/vault/:product([^/]*)*/:vertical()/completion",
    meta: completionuvWIHsMlYxp2yDeD5fjvFoiPBc0SeFciMB9XI94hfgkMeta || {},
    component: () => import("/codebuild/output/src1136870220/src/packages/nuxt/pages/dashboard/vault/[...product]/[vertical]/completion.vue")
  },
  {
    name: "health-insurance-visitors-visa-ovhc-quotes-results",
    path: "/health-insurance/visitors-visa-ovhc/quotes-results",
    component: () => import("/codebuild/output/src1136870220/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/quotes-results.vue")
  },
  {
    name: "life-insurance-income-protection-quote-confirmation",
    path: "/life-insurance/income-protection/quote/confirmation",
    component: () => import("/codebuild/output/src1136870220/src/packages/nuxt/pages/life-insurance/income-protection/quote/confirmation.vue")
  },
  {
    name: "dashboard-vault-product-form-energy-step-step",
    path: "/dashboard/vault/:product([^/]*)*/form/energy/step/:step()",
    meta: _91step_93SCgdEtYogRvm6zd807p6N3Nb6WmGHHYSRBnbV6wP47cMeta || {},
    component: () => import("/codebuild/output/src1136870220/src/packages/nuxt/pages/dashboard/vault/[...product]/form/energy/step/[step].vue")
  },
  {
    name: "dashboard-vault-product-form-generic-step-step",
    path: "/dashboard/vault/:product([^/]*)*/form/generic/step/:step()",
    meta: _91step_93lkcE3Ue98rDBP68fW1vZ1BI1WR90vJmAdj0FLagOSk8Meta || {},
    component: () => import("/codebuild/output/src1136870220/src/packages/nuxt/pages/dashboard/vault/[...product]/form/generic/step/[step].vue")
  },
  {
    name: "health-insurance-visitors-visa-ovhc-application-step",
    path: "/health-insurance/visitors-visa-ovhc/application/:step()",
    component: () => import("/codebuild/output/src1136870220/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/application/[step].vue")
  },
  {
    name: "dashboard-vault-product-form-home-loan-step-step",
    path: "/dashboard/vault/:product([^/]*)*/form/home-loan/step/:step()",
    meta: _91step_93cI2ty5twKPRiWpq5_45ZJvfL9RUukibJJMTwTjv00XW_gMeta || {},
    component: () => import("/codebuild/output/src1136870220/src/packages/nuxt/pages/dashboard/vault/[...product]/form/home-loan/step/[step].vue")
  },
  {
    name: "health-insurance-students-offer-university-form",
    path: "/health-insurance/students-offer/:university()/form",
    component: () => import("/codebuild/output/src1136870220/src/packages/nuxt/pages/health-insurance/students-offer/[university]/form/index.vue")
  },
  {
    name: "dashboard-vault-product-vertical-additional-details",
    path: "/dashboard/vault/:product([^/]*)*/:vertical()/additional-details",
    meta: additional_45detailsRZJpGyUkU587XQpo5Ydur2rNvVj671d3UOOzQ21j208Meta || {},
    component: () => import("/codebuild/output/src1136870220/src/packages/nuxt/pages/dashboard/vault/[...product]/[vertical]/additional-details.vue")
  },
  {
    name: "dashboard-vault-product-form-life-insurance-step-step",
    path: "/dashboard/vault/:product([^/]*)*/form/life-insurance/step/:step()",
    meta: _91step_93VNneN9i2sFDI0BFcQ7L3EHOH45O02VseAtq4ymAxb1EMeta || {},
    component: () => import("/codebuild/output/src1136870220/src/packages/nuxt/pages/dashboard/vault/[...product]/form/life-insurance/step/[step].vue")
  },
  {
    name: "dashboard-vault-product-form-health-insurance-step-step",
    path: "/dashboard/vault/:product([^/]*)*/form/health-insurance/step/:step()",
    meta: _91step_93_2W_45lwfXlWZ6FELa5EDbxEb2_KNA95akmerNYLGPoDgMeta || {},
    component: () => import("/codebuild/output/src1136870220/src/packages/nuxt/pages/dashboard/vault/[...product]/form/health-insurance/step/[step].vue")
  },
  {
    name: "health-insurance-students-offer-university-terms-conditions",
    path: "/health-insurance/students-offer/:university()/terms-conditions",
    component: () => import("/codebuild/output/src1136870220/src/packages/nuxt/pages/health-insurance/students-offer/[university]/terms-conditions/index.vue")
  },
  {
    name: "health-insurance-budget-hub-health-insurance-average-cost-calculator",
    path: "/health-insurance/budget-hub/health-insurance-average-cost-calculator",
    component: () => import("/codebuild/output/src1136870220/src/packages/nuxt/pages/health-insurance/budget-hub/health-insurance-average-cost-calculator/index.vue")
  },
  {
    name: "Guides",
    path: "/credit-cards/guides",
    component: () => import("/codebuild/output/src1136870220/src/packages/nuxt/pages/[vertical]/guides.vue")
  },
  {
    name: "Companies",
    path: "/credit-cards/companies",
    component: () => import("/codebuild/output/src1136870220/src/packages/nuxt/pages/[vertical]/companies/index.vue")
  }
]